/* eslint-disable no-unused-vars */
export default {
  iconExtensionFilesOnly,
  iconExtensionFilesFolder,
  iconExtensionFilesFolder_new,
  iconExtensionFilesOnly_new,
  fn_seticon
};

// SET ICON FILES&FOLDER
function fn_seticon(parameter) {
  let dataicon;
  if (parameter === "xlsx" || parameter === "xls") {
    dataicon = ["mdi-file-excel", "#00733b"];
  } else if (parameter === "pptx" || parameter === "ppt") {
    dataicon = ["mdi-file-powerpoint", "#e03303"];
  } else if (parameter === "docx" || parameter === "doc") {
    dataicon = ["mdi-file-word", "#0263d1"];
  } else if (parameter === "zip") {
    dataicon = ["mdi-zip-box", "blue-grey"];
  } else if (parameter === "pdf") {
    dataicon = ["mdi-file-pdf", "#e5252a"];
  } else if (parameter === "xml") {
    dataicon = ["mdi-file-code", "orange"];
  } else if (parameter === "txt" || parameter === "txt") {
    dataicon = ["mdi-note-text", "blue-grey"];
  } else if (parameter === "csv") {
    dataicon = ["mdi-file-table", "green"];
  } else if (
    parameter === "jpeg" ||
    parameter === "jpg" ||
    parameter === "png" ||
    parameter === "gif"
  ) {
    dataicon = ["mdi-file-image", "#0ac963"];
  } else if (
    parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4" ||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"
  ) {
    dataicon = ["mdi-file-video", "purple"];
  } else if (parameter === "shortcut") {
    dataicon = ["mdi-file-link", "pink"];
  } else if (parameter === "r" || parameter === "folder") {
    dataicon = ["mdi-folder", "#ffc107"];
  } else {
    dataicon = ["mdi-file", "black"];
  }
  return dataicon;
}

// SET ICON FILES&FOLDER
function iconExtensionFilesFolder(_type, _extension) {
  let dataicon;
  let parameter;

  if (typeof _extension === "string") {
    parameter = _extension.toLowerCase();
  } else {
    parameter = _extension;
  }

  if (_type === "folder") {
    dataicon = ["folder", 
    // "blue"
    "#FDD361"
  ];
  } else {
    if (parameter === "xlsx" || parameter === "xls") {
      dataicon = ["mdi-file-excel-outline", "green"];
    } else if (parameter === "pptx" || parameter === "ppt") {
      dataicon = ["mdi-file-powerpoint-outline", "orange"];
    } else if (parameter === "docx" || parameter === "doc") {
      dataicon = ["mdi-file-word-outline", "primary"];
    } else if (parameter === "zip") {
      dataicon = ["mdi-zip-box-outline", "blue-grey"];
    } else if (parameter === "pdf") {
      dataicon = ["mdi-file-pdf-outline", "red"];
    } else if (parameter === "xml") {
      dataicon = ["mdi-file-code-outline", "orange"];
    } else if (parameter === "txt" || parameter === "txt") {
      dataicon = ["mdi-note-text-outline", "blue-grey"];
    } else if (parameter === "csv") {
      dataicon = ["mdi-file-table-outline", "green"];
    } else if (
      parameter === "jpeg" ||
      parameter === "jpg" ||
      parameter === "png" ||
      parameter === "gif"
    ) {
      dataicon = ["mdi-file-image-outline", "blue-grey"];
    } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
    else {
      dataicon = ["mdi-file-outline", "black"];
    }
  }
  return dataicon;
}
// SET ICON FILES&FOLDER NEW
function iconExtensionFilesFolder_new(_type, _extension) {
  let dataicon;
  let parameter;

  if (typeof _extension === "string") {
    parameter = _extension.toLowerCase();
  } else {
    parameter = _extension;
  }

  if (_type === "folder") {
    dataicon = ["folder", 
    // "blue"
    "#FDD361"
  ];
  } else {
    if (parameter === "xlsx" || parameter === "xls") {
      dataicon = ["mdi-file-excel", "green"];
    } else if (parameter === "pptx" || parameter === "ppt") {
      dataicon = ["mdi-file-powerpoint", "orange"];
    } else if (parameter === "docx" || parameter === "doc") {
      dataicon = ["mdi-file-word", "primary"];
    } else if (parameter === "zip") {
      dataicon = ["mdi-zip-box", "blue-grey"];
    } else if (parameter === "pdf") {
      dataicon = ["mdi-file-pdf", "red"];
    } else if (parameter === "xml") {
      dataicon = ["mdi-file-code", "orange"];
    } else if (parameter === "txt" || parameter === "txt") {
      dataicon = ["mdi-note-text", "blue-grey"];
    } else if (parameter === "csv") {
      dataicon = ["mdi-file-table", "green"];
    } else if (
      parameter === "jpeg" ||
      parameter === "jpg" ||
      parameter === "png" ||
      parameter === "gif"
    ) {
      dataicon = ["mdi-file-image", "blue-grey"];
    } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link","pink"]
    }else if(parameter === "folder"){
      dataicon=["mdi-folder","#FDD361"]
    }
    else {
      dataicon = ["mdi-file", "black"];
    }
  }
  return dataicon;
}

// SET ICON FILES ONLY
function iconExtensionFilesOnly(_extension) {
  let dataicon;
  let parameter;
  if (typeof _extension === "string") {
    parameter = _extension.toLowerCase();
  } else {
    parameter = _extension;
  }
  if (parameter === "xlsx" || parameter === "xls") {
    dataicon = ["mdi-file-excel-outline", "green"];
  } else if (parameter === "pptx" || parameter === "ppt") {
    dataicon = ["mdi-file-powerpoint-outline", "orange"];
  } else if (parameter === "docx" || parameter === "doc") {
    dataicon = ["mdi-file-word-outline", "primary"];
  } else if (parameter === "zip") {
    dataicon = ["mdi-zip-box-outline", "blue-grey"];
  } else if (parameter === "pdf") {
    dataicon = ["mdi-file-pdf-outline", "red"];
  } else if (parameter === "xml") {
    dataicon = ["mdi-file-code-outline", "orange"];
  } else if (parameter === "txt" || parameter === "txt") {
    dataicon = ["mdi-note-text-outline", "blue-grey"];
  } else if (parameter === "csv") {
    dataicon = ["mdi-file-table-outline", "green"];
  } else if (
    parameter === "jpeg" ||
    parameter === "jpg" ||
    parameter === "png" ||
    parameter === "gif"
  ) {
    dataicon = ["mdi-file-image-outline", "blue-grey"];
  } else if(parameter === "avi" ||
  parameter === "mpeg" ||
  parameter === "mov" ||
  parameter === "mp4"||
  parameter === "mkv" ||
  parameter === "wmv" ||
  parameter === "3gp" ||
  parameter === "flv"){
    dataicon = ["mdi-file-video-outline", "purple"];
  }
  else {
    dataicon = ["mdi-file-outline", "black"];
  }

  return dataicon;
}

function iconExtensionFilesOnly_new(_extension) {
  let dataicon;
  let parameter;
  if (typeof _extension === "string") {
    parameter = _extension.toLowerCase();
  } else {
    parameter = _extension;
  }
  if (parameter === "xlsx" || parameter === "xls") {
    dataicon = ["mdi-file-excel", "green"];
  } else if (parameter === "pptx" || parameter === "ppt") {
    dataicon = ["mdi-file-powerpoint", "orange"];
  } else if (parameter === "docx" || parameter === "doc") {
    dataicon = ["mdi-file-word", "primary"];
  } else if (parameter === "zip") {
    dataicon = ["mdi-zip-box", "blue-grey"];
  } else if (parameter === "pdf") {
    dataicon = ["mdi-file-pdf", "red"];
  } else if (parameter === "xml") {
    dataicon = ["mdi-file-code", "orange"];
  } else if (parameter === "txt" || parameter === "txt") {
    dataicon = ["mdi-note-text", "blue-grey"];
  } else if (parameter === "csv") {
    dataicon = ["mdi-file-table", "green"];
  } else if (
    parameter === "jpeg" ||
    parameter === "jpg" ||
    parameter === "png" ||
    parameter === "gif"
  ) {
    dataicon = ["mdi-file-image", "blue-grey"];
  } else if(parameter === "avi" ||
  parameter === "mpeg" ||
  parameter === "mov" ||
  parameter === "mp4"||
  parameter === "mkv" ||
  parameter === "wmv" ||
  parameter === "3gp" ||
  parameter === "flv"){
    dataicon = ["mdi-file-video", "purple"];
  }
  else {
    dataicon = ["mdi-file", "black"];
  }

  return dataicon;
}
