<template>
  <v-app :style="colorVapp">
    <div>
      <onebox_toolbar
        :quicksearch="true"
        :heardertextsearch="heardertextsearch"
        :parentfolder="parentfolder"
        @inputdata="filteredItems"
        @loadfile="loadsharefolder()"
        @callparentfolder="sendParentfolder()"
        @callstorage="loadstorage()"
        @advancesearch="fn_advancesearch"
      ></onebox_toolbar>
      <v-content>
        <v-card class="elevation-0">
          <!-- <v-divider></v-divider> -->
          <v-overlay :value="processloader" absolute :color="color.BG" dark>
            <v-progress-circular
              indeterminate
              size="64"
              :color="color.theme"
            ></v-progress-circular>
            <br />
            <br />
            <span :style="colorProgress">&nbsp; &nbsp; loading</span>
          </v-overlay>
          <v-list nav :color="color.BG" v-if="resolutionScreen >= 500">
            <v-list-item class="text-left" v-if="heardertextsearch === false">
              <v-list-item-avatar class="text-center pt-1">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="21">folder_shared</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <!-- <v-list-item-title> -->
                <!-- <span
                  v-if="$route.query.type === 2"
                  style="color: #1565C0;  font-size: 18px; font-weight: 600;"
                >{{ $t('toolbar.myinbox.Businessname')}}</span>
                <span
                  v-else
                  style="color: #1565C0;  font-size: 18px; font-weight: 600;"
                >{{ $t('toolbar.myinbox.Personalname')}}</span> -->
                <!-- <span :style="headerPage"> {{ $t("toolbar.mysharefile") }}</span>
              </v-list-item-title> -->
                <v-list-item-title class="ml-n2 pt-1 pb-1">
                  <v-btn rounded text @click="loadsharefolder">
                    <span :style="headerPage">{{
                      $t("toolbar.mysharefile")
                    }}</span>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="text-left" v-else>
              <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-magnify</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage"
                  >&nbsp;{{ $t("tablefile.searchtext") }}</span
                >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list nav :color="color.BG" v-else class="mb-n2">
            <v-list-item class="text-left" v-if="heardertextsearch === false">
              <v-chip :style="headerChipPage" :color="$vuetify.theme.dark ? '#989898' : 'white'">
                <v-avatar class="text-center">
                  <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme" dark size="20">folder_shared</v-icon>
                </v-avatar>
                <v-list-item-title class="ml-2">
                    <span :style="headerPageMobile">{{
                      $t("toolbar.mysharefile")
                    }}</span>
                </v-list-item-title>
              </v-chip>
            </v-list-item>
            <v-list-item class="text-left" v-else>
              <v-chip :style="headerChipPage" :color="$vuetify.theme.dark ? '#989898' : 'white'">
              <v-list-item-avatar class="text-center">
                <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme" dark size="20">mdi-magnify</v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="ml-n2">
                <span :style="headerPageMobile">&nbsp;{{ $t("tablefile.searchtext") }}</span>
                </v-list-item-title>
              </v-chip>
            </v-list-item>
          </v-list>
          <v-card
            class="elevation-0"
            :color="color.BG"
            id="thiscontainer_sharedwithme"
          >
            <v-card-text class="pa-0">
              <v-container fluid>
                <v-layout row wrap justify-center>
                  <v-flex
                    lg12
                    class="ma-2 ml-4 mr-4"
                    v-if="loaddataprogress === true"
                  >
                    <div class="text-center">
                      <v-progress-circular
                        :size="50"
                        :color="color.theme"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-flex>
                  <v-flex lg12 xs12 class="pa-4" v-else>
                    <v-layout
                      row
                      wrap
                      class="pl-3 pr-3"
                      v-if="resolutionScreen >= 500"
                    >
                      <v-flex lg2 xs4>
                        <v-select
                          prepend-inner-icon="sort"
                          hide-details
                          outlined
                          solo
                          dense
                          v-model="size"
                          :items="listsize"
                          class="text-center "
                          :item-color="color.theme"
                          :color="color.theme"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                        </v-select>
                      </v-flex>
                      <!-- ปิดชื่อฟิลเตอร์ตามหัวข้อสีเขียว -->
                      <!-- <v-flex
                        lg4
                        offset-lg6
                        class="pl-3 pr-3 hidden-md-and-down"
                      >
                        <v-alert
                          dense
                          height="41"
                          v-model="clickSort"
                          :color="color.alertSort"
                          dark
                          v-show="clickSort"
                          style="margin-bottom: 0%;"
                          ><div>
                            <v-icon
                              :color="color.alertText"
                              v-if="multiSort === true"
                            >
                              mdi-chevron-up</v-icon
                            >
                            <v-icon
                              :color="color.alertText"
                              v-else-if="multiSort === false"
                            >
                              mdi-chevron-down</v-icon
                            >
                            <span :style="colorSort" v-if="sortby === 'name'">{{
                              $t("Filter.name")
                            }}</span>
                            <span
                              :style="colorSort"
                              v-else-if="sortby === 'date'"
                              >{{ $t("Filter.date") }}</span
                            >
                            <span
                              :style="colorSort"
                              v-else-if="sortby === 'size'"
                              >{{ $t("Filter.size") }}</span
                            >
                            <span
                              :style="colorSort"
                              v-else-if="sortby === 'owner'"
                              >{{ $t("Filter.owner") }}</span
                            >
                            <v-btn
                              width="24"
                              height="24"
                              style="float: right;"
                              text
                              fab
                              x-small
                              :color="color.alertSort"
                              dark
                              @click="checkSort"
                            >
                              <v-icon :color="color.alertText">
                                mdi-close-circle</v-icon
                              ></v-btn
                            >
                          </div></v-alert
                        ></v-flex
                      > -->
                    </v-layout>
                    <!-- <br> -->
                    <v-layout v-if="resolutionScreen >= 500">
                      <v-flex xs4 lg4>
                        <div
                          v-if="statusmutipledownload === false"
                          class="pt-2 hidden-md-and-down"
                        >
                          <v-btn
                            @mouseover="show_multipledownload = true"
                            @mouseleave="show_multipledownload = false"
                            :color="color.theme"
                            rounded
                            :dark="color.darkTheme"
                            class="ma-1"
                            @click="(arrayfileidselect = []),
                              (statusmutipledownload = true),
                                (show_mutipledownload = false)
                            "
                            fab
                            small
                          >
                            <v-icon>mdi-download-multiple</v-icon> &nbsp;
                            <!-- {{ $t('myinboxPage.multipledownload')}} -->
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_multipledownload"
                              :content="$t('balloon.menu_download')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_multipledownload"
                              :content="$t('balloon.menu_download')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </div>
                        <div v-else class="pt-2">
                          <v-btn
                            @mouseover="show_download = true"
                            @mouseleave="show_download = false"
                            fab
                            small
                            color="primary"
                            class="ma-1"
                            @click="multipledownload()"
                          >
                            <v-icon>mdi-download-multiple</v-icon>
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_download"
                              :content="$t('balloon.download')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_download"
                              :content="$t('balloon.download')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                            @mouseover="show_clear = true"
                            @mouseleave="show_clear = false"
                            fab
                            small
                            color="error"
                            class="ma-1  elevation-3"
                            @click="
                              (statusmutipledownload = false),
                                clearmultipledownload(),
                                (show_clear = false)
                            "
                          >
                            <v-icon>clear</v-icon>
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-20px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex xs8 lg8 class="text-right" v-if="heardertextsearch === false">
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>folder</v-icon>
                          {{ countfolder }}
                          {{ countfolder > 1 ? $t("folders") : $t("folder") }}
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-file</v-icon>
                          {{ countfile }}
                          {{ countfile > 1 ? $t("files") : $t("file") }}
                        </v-chip>
                      </v-flex>
                      <v-flex xs8 lg8 class="text-right" v-else>
                        <v-chip class="mt-4 white--text" :color="color.theme" >{{ $t("count_data_Advasearch") }} : {{ count_data_adv_ }}</v-chip>
                      </v-flex>
                    </v-layout>
                    <!-- หน้าจอxs -->
                    <v-layout row wrap class="mt-n4 mr-n4" v-else>
                      <!-- <v-flex xs5 sm3>
                        <v-select
                          prepend-inner-icon="sort"
                          hide-details
                          outlined
                          solo
                          dense
                          v-model="size"
                          :items="listsize"
                          class="text-center ma-1 ml-3"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                        </v-select>
                      </v-flex>
                      <v-flex xs7 sm9 class="text-right">
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>folder</v-icon>
                          <span
                            >{{ countfolder }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfolder > 1 ? $t("folders") : $t("folder")
                            }}</span></span
                          >
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-file</v-icon>
                          <span
                            >{{ countfile }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfile > 1 ? $t("files") : $t("file")
                            }}</span></span
                          >
                        </v-chip>
                      </v-flex> -->
                      <v-flex xs3 class="text-left">
                        <v-menu offset-y>
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn x-small rounded
                              :color="color.theme" 
                              v-bind="attrs"
                              v-on="on"
                              text
                              style="font-size:16px; letter-spacing: 0px;"
                              class="ml-2"
                            >
                              <v-icon class="mr-2">sort</v-icon><span class="font-weight-bold" :color="color.theme">{{listsizemenu.find(x => x.value === size).value}}</span>
                            </v-btn>
                          </template>
                          <v-list dense nav :style="roundListSort">
                            <v-list-item-group
                              v-model="size"
                              :color="color.theme"
                            >
                              <template  v-for="(item, i) in listsizemenu">
                                <v-list-item
                                  :key="`item-${i}`"
                                  :value="item.value"
                                >
                                  <template>
                                    <v-list-item-content>
                                      <v-list-item-title :style="size === item.value ? listOnBold : listNoBold" >{{ item.value }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </v-flex>
                      <v-flex xs9 class="text-right ml-n4" v-if="heardertextsearch === true">
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4 white--text" :color="color.theme">{{ $t("count_data_Advasearch") }} : {{ count_data_adv_ }}</v-chip>
                      </v-flex>
                      <v-flex xs9 class="text-right ml-n4" v-else>
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4 mr-2" color="#1876D1" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">folder</v-icon>
                          <span style="font-weight: 700; font-size:16px;"
                            >{{ countfolder }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfolder > 1 ? $t("folders") : $t("folder")
                            }}</span></span
                          >
                        </v-chip>
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4" color="#5AB685" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">mdi-file-document</v-icon>
                          <span style="font-weight: 700; font-size:16px;"
                            >{{ countfile }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfile > 1 ? $t("files") : $t("file")
                            }}</span></span
                          >
                        </v-chip>
                      </v-flex>
                    </v-layout>

                    <br />
                    <v-card v-if="resolutionScreen < 500" class="mt-n2" :style="displayMobile">
                      <v-list
                        two-line
                        class="elevation-0 px-2"
                        v-if="paginatedData.length !== 0"
                        :style="styleDisplayMobile"
                      >
                        <v-list-item
                          v-for="item in paginatedData"
                          :key="item.title"
                          @contextmenu="rightclickfileandfolder($event, item)"
                          :style="displayMobileForListItem"
                          @click="
                            gotodirectory(item.file_id, item.file_type, item),
                              fn_newpreviewfile(item)
                          "
                          style="cursor: pointer;"
                        >
                          <v-list-item-action>
                            <v-badge
                              color="orange"
                              overlap
                              icon="link"
                              v-if="
                                item.file_status_sharelink === 'Y' ||
                                  item.file_status_sharelink === 'P'
                              "
                            >
                              <template v-slot:badge>
                                <v-icon large :color="item.file_icon[1]">{{
                                  item.file_icon[0]
                                }}</v-icon>
                              </template>
                            </v-badge>
                            <v-badge
                              color="red"
                              overlap
                              icon="lock"
                              v-else-if="item.editing_status === 'Y'">
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                            <v-badge
                              color="red"
                              overlap
                              icon="mdi-sync-circle"
                              v-else-if="item.editing_status === 'S'">
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                            <v-icon v-else large :color="item.file_icon[1]">{{
                              item.file_icon[0]
                            }}</v-icon>
                          </v-list-item-action>
                          <!-- <v-list-item-avatar>
                        <v-badge color="orange" overlap>
                          <template v-slot:badge>
                            <v-icon v-if="item.file_status_sharelink === 'Y'">link</v-icon>
                          </template>
                          <v-icon
                            x-large
                            :color="item.file_icon[1]"
                            style="font-weight: 100"
                          >{{ item.file_icon[0] }}</v-icon>
                        </v-badge>  
                        </v-list-item-avatar> -->

                          <v-list-item-content>
                            <v-list-item-title style="font-size: 14px;">
                              <v-icon
                                v-if="item.status_lock_data === 'Y'"
                                color="error"
                                small
                                >lock</v-icon
                              >
                              <div style="display: inline-block;" 
                                v-if="item.status_hilightword === undefined">
                                {{ item.file_name }}
                              </div>
                              <div style="display: inline-block;" 
                                v-html="fn_hilightWord(item.file_name)" v-else>
                              </div>
                              <!-- <v-icon class="pr-1" color="red" x-small v-if="item.relation_status === 0">device_hub</v-icon>
                          <v-icon class="pr-1" color="amber accent-4" x-small v-else-if="item.relation_status === 1">device_hub</v-icon> -->
                            </v-list-item-title>
                            <v-list-item-subtitle
                              style="font-size: 12px;"
                            >{{calculatesize(item.file_size)}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-show="item.editing_status === 'Y'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'"> กำลังแก้ไข</v-chip>
                              <v-chip x-small color="primary" v-else> editting</v-chip>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-show="item.editing_status === 'S'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'"> กำลังซิงค์...</v-chip>
                              <v-chip x-small color="primary" v-else>Syncing...</v-chip>
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn
                              class="elevation-0"
                              fab
                              small
                              @click.stop="rightclickfileandfolder($event, item)"
                              icon
                            >
                              <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-card-text class="mt-4 mb-4" v-else>
                        <v-img
                          :src="require('../assets/img/icon_empty_data.png')"
                          max-width="132"
                          max-height="150"
                          class="mx-auto"
                        />
                        <v-list-item-title
                          :style="'font-size: 16px; font-weight: 700; line-height: 24px; color:' + color.theme + ';'"
                          class="text-center mt-8"
                        > {{$t('tablefile.empty')}} </v-list-item-title>
                      </v-card-text>
                      <!-- <v-list v-else class=" elevation-1">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center"
                              v-text="$t('tablefile.empty')"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list> -->
                    </v-card>

                    <div v-else>
                      <v-data-table
                        v-model="filemultipledownload"
                        :headers="headers"
                        :items="rootsharefile"
                        :single-select="false"
                        :search="search"
                        item-key="file_id"
                        :show-select="statusmutipledownload"
                        :no-data-text="$t('tablefile.empty')"
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        class="elevation-0"
                        @page-count="pageCount = $event"
                      >
                        <template v-slot:[`header.file_type`]="{ header }">
                          <span :style="headerTable">{{ header.text }}</span>
                        </template>
                        <template v-slot:[`header.file_name`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'name'),
                                (clickSort = true),
                                sortFile(rootsharefile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_shareby`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'shareby'),
                                (clickSort = true),
                                sortFile(rootsharefile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_size`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'size'),
                                (clickSort = true),
                                sortFile(rootsharefile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_date`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'date'),
                                (clickSort = true),
                                sortFile(rootsharefile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.permission`]="{ header }">
                          <span :style="headerTable">{{
                            $t(header.text)
                          }}</span>
                        </template>
                        <template v-slot:[`header.file_status`]="{ header }">
                          <span :style="headerTable">{{
                            $t(header.text)
                          }}</span>
                        </template>

                        <template v-slot:item="props">
                          <tr
                            @contextmenu="rightclickfileandfolder($event, props.item)"
                            @dblclick="
                              gotodirectory(
                                props.item.file_id,
                                props.item.file_type,
                                props.item
                              ),
                                fn_newpreviewfile(props.item)
                            "
                            :style="selectItemTable(props.item.file_id, 'table') + 'cursor: pointer;'"
                            @click="setSelectitemtable(props.item)"
                          >
                            <td
                              class="text-center"
                              v-show="statusmutipledownload"
                            >
                              <v-checkbox
                                v-if="props.item.file_type != 'folder'"
                                v-model="filemultipledownload"
                                :value="props.item"
                              />
                              <!-- checkbox ทั้งหมด -->
                              <!-- <v-checkbox
                              v-model="filemultipledownload"
                              :value="props.item"
                            /> -->
                            </td>
                            <td width="7%" class="text-center">
                              <v-badge
                                color="orange"
                                overlap
                                icon="link"
                                v-if="
                                  props.item.file_status_sharelink === 'Y' ||
                                    props.item.file_status_sharelink === 'P'
                                "
                              >
                                <v-icon
                                  large
                                  :color="props.item.file_icon[1]"
                                  >{{ props.item.file_icon[0] }}</v-icon
                                >
                              </v-badge>
                              <v-badge color="red" overlap icon="lock" v-else-if="props.item.editing_status === 'Y'">
                                <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              </v-badge>
                              <v-badge color="red" overlap icon="mdi-sync-circle" v-else-if="props.item.editing_status === 'S'">
                                <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              </v-badge>
                              <v-icon
                                v-else
                                large
                                :color="props.item.file_icon[1]"
                                >{{ props.item.file_icon[0] }}</v-icon
                              >
                            </td>
                            <td width="35%">
                              <div style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;width:420px">
                              <v-icon
                                v-if="props.item.status_lock_data === 'Y'"
                                color="error"
                                small
                                >lock</v-icon
                              >
                              <div style="display: inline-block; margin-left: 5px;"
                                v-if="props.item.status_hilightword === undefined">
                                {{ props.item.file_name}}
                              </div>
                              <div v-else v-html="fn_hilightWord(props.item.file_name)" 
                                style="display: inline-block; margin-left: 5px;">
                              </div>
                              <v-icon
                                class="pr-1"
                                color="red"
                                x-small
                                v-if="props.item.relation_status === 0"
                                >device_hub</v-icon
                              >
                              <v-icon
                                class="pr-1"
                                color="amber accent-4"
                                x-small
                                v-else-if="props.item.relation_status === 1"
                                >device_hub</v-icon
                              >
                              <div v-show="props.item.editing_status === 'Y'">
                                <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                  > กำลังแก้ไข</v-chip
                                >
                                <v-chip x-small color="primary" v-else
                                  > editting</v-chip
                                >
                              </div>
                              <div v-show="props.item.editing_status === 'S'">
                                <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                  >กำลังซิงค์...</v-chip
                                >
                                <v-chip x-small color="primary" v-else
                                  >Syncing...</v-chip
                                >
                              </div>
                              </div>
                            </td>
                            <!-- <v-icon small color="orange" v-if="props.item.file_permission === 'E'|| props.item.file_permission === 'De'">mdi-pencil</v-icon> -->
                            <td width="15%" class="text-center" v-if="$t('default') === 'th'">
                              {{ props.item.file_owner_th }}
                              <v-icon
                                small
                                color="orange"
                                v-if="props.item.file_permission === 'V'"
                                >mdi-eye</v-icon
                              >
                            </td>
                            <td width="10%" class="text-center" v-else>
                              {{ props.item.file_owner_eng }}
                              <v-icon
                                small
                                color="orange"
                                v-if="props.item.file_permission === 'V'"
                                >mdi-eye</v-icon
                              >
                            </td>
                            <td class="text-center">
                              {{ calculatedate(props.item.file_date) }}
                            </td>
                            <td class="text-center">
                              {{ calculatesize(props.item.file_size) }}
                            </td>
                            <td class="text-center">
                              {{
                                props.item.permission_account === "01"
                                  ? $t("permissionsharefile.viewonly")
                                  : props.item.permission_account === "02"
                                  ? $t("permissionsharefile.download")
                                  : props.item.permission_account === "03"
                                  ? $t("permissionsharefile.upload")
                                  : props.item.permission_account === "04"
                                  ? $t("permissionsharefile.edit")
                                  : props.item.permission_account === "05"
                                  ? $t("permissionsharefile.delete")
                                  : "-"
                              }}
                            </td>
                            <td class="text-center" :disabled="disableByPackage">
                              <!-- <div v-if="item.account_sender !== dataAccountId">  -->
                              <v-btn
                                text
                                class="elevation-0"
                                fab
                                small
                                @click="
                                  setPermissionFileStar(props.item, 'N', 'st')
                                "
                                v-if="props.item.file_status === 'S'"
                                :disabled="disableByPackage"
                              >
                                <v-icon color="amber">star</v-icon>
                              </v-btn>
                              <v-btn
                                class="elevation-0"
                                text
                                fab
                                small
                                @click="
                                  setPermissionFileStar(props.item, 'S', 'st')
                                "
                                :disabled="disableByPackage"
                                v-else
                              >
                                <v-icon>star_border</v-icon>
                              </v-btn>
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                @click="rightclickfileandfolder($event, props.item)"
                                outlined
                              >
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                              <!-- </div> -->
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <br />
                    <v-layout row wrap justify-center>
                      <v-flex xs11 lg11 v-if="rootsharefile.length > 0">
                        <v-pagination
                          v-model="page"
                          :length="pageCount || 0"
                          :color="color.theme"
                          @input="changepage()"
                        ></v-pagination>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
              <previewfile
                :show="opennewpreviewfile"
                :arrayfile="arrayfile"
                :filedata_1="currentfile"
                :parentfolder="parentfolder"
                :param_preview="param_preview"
                @closepreviewfile="opennewpreviewfile = false"
                @openpreviewfile="opennewpreviewfile = true"
                @reload="loadsharefolder()"
              ></previewfile>
              <!-- <newpreviewfile
            :parentfolder="parentfolder"
            :show="opennewpreviewfile"
            :filedata="currentfile"
            @closenewpreviewfile ="opennewpreviewfile = false"
            @opennewpreviewfile="opennewpreviewfile = true"
            ></newpreviewfile> -->
              <uploadfiles
                :show="openUploadfiles"
                :parentfolder="parentfolder"
                @close="
                  loadstorage(), loadsharefolder(), (openUploadfiles = false)
                "
              ></uploadfiles>
              <uploadfolder
                :show="openUploadfolder"
                :parentfolder="parentfolder"
                @close="loadsharefolder(), (openUploadfolder = false)"
              ></uploadfolder>
              <mainrightclick
                :show="showMenu"
                :parentfolder="parentfolder"
                :AreaX="x"
                :AreaY="y"
                @loaddatafrommainright="
                  loadstorage(), loadsharefolder(), (showMenu = false)
                "
                @closemainonly="showMenu = false"
              ></mainrightclick>
              <!-- <listfile @closemaincontextmenu="showMenu = false"></listfile> -->
              <!-- <currentfilerightclick
            :parentfolder="parentfolder"
            :show="showcurrentMenu"
            :AreaX="x"
            :AreaY="y"
            @callupdatestatusfile="setPermissionFileStar"
            @closecurrent="loadstorage(),loadsharefolder(), showcurrentMenu= false"
            @closecurrentonly="showcurrentMenu = false"
            :file="currentfile"
            ></currentfilerightclick>-->
              <sharefilerightclick
                :show="showcurrentMenu"
                :AreaX="x"
                :AreaY="y"
                :file="currentfile"
                :confirm="confirmdelete"
                :arrayfile="rootsharefile"
                @callupdatestatusfile="setPermissionFileStar"
                @closecurrent="showcurrentMenu = false"
                @reload="loadsharefolder(), loadstorage()"
              ></sharefilerightclick>
              <dialogcheckmultidownload
                :show="opendialogcheckmultidownload"
                @closedialog="
                  (opendialogcheckmultidownload = false),
                    (filemultipledownload = [])
                "
                :data="filemultipledownload"
              ></dialogcheckmultidownload>
              <rightcheckpassword
                :show="opendialog_right"
                :filedata="newdata"
                :key_st="check_btn"
                @closedialog="opendialog_right = false"
                @close_c_star="opendialog_checklog = false"
                @callapi="
                  loadstorage(), loadfolder(), (opendialog_right = false)
                "
                @close_star="setPermissionFileStar(newdata, star_status)"
              />
              <checkpassword
                :show="opendialog_checklog"
                @closedialog="backtopreventfolder()"
                @close_c_star="opendialog_checklog = false"
                @close="(opendialog_checklog = false), gotodirectory()"
                :filedata="newdata"
                :key_st="check_btn"
                @close_star="setPermissionFileStar(newdata, star_status)"
              />
            </v-card-text>
          </v-card>
        </v-card>
      </v-content>
      <div style="visibility:hidden;" id="dropzone">
        <div id="textnode">
          <v-icon size="200" dark>cloud_upload</v-icon>
          <h3>วางไฟล์เพื่อทำการอัปโหลด</h3>
        </div>
      </div>
      <!-- snackbardowload -->
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackbardowload"
          bottom
          right
          color="white"
          :timeout="timeout"
          ><v-list :color="color.chipText" style="padding-top: 0px;">
            <v-toolbar
              :color="color.theme"
              dense
              short
              :dark="color.darkTheme"
              flat
              fab
              >{{ $t("myinboxPage.dowload.dowloading") }}<v-spacer></v-spacer
              ><v-btn
                color="red"
                text
                fab
                @click="snackbardowload = false"
                x-small
                ><v-icon>mdi-window-close</v-icon>
              </v-btn> </v-toolbar
            ><v-list-item dense style="height:40px; width:400px;"
              ><v-list-item-content>
                <v-list-item-title style="color:black">
                  {{ datetime }}</v-list-item-title
                >
              </v-list-item-content>
              <v-progress-circular
                :rotate="90"
                :size="35"
                :width="3"
                :value="percentCompleted"
                color="teal"
                style="margin-left:25px"
                >&nbsp; {{ percentCompleted }}
              </v-progress-circular>
            </v-list-item></v-list
          >
        </v-snackbar>
      </div>
    </div>
  </v-app>
</template>
<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gb_advanceSearch from "@/globalFunctions/advanceSearch";
import VueCookies from "vue-cookies";
import gb_checkeditonline from "@/globalFunctions/checkinetjv";

// Import Component
const uploadfolder = () => import("../components/upload/dialog-uploadfolder");
const uploadfiles = () => import("../components/upload/dialog-uploadfiles2");
const currentfilerightclick = () =>
  import("../components/contextmenu/currentfilerightclick");
const mainrightclick = () => import("../components/contextmenu/mainrightclick");
const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
const previewfile = () => import("../components/optional/dialog-preview");
const sharefilerightclick = () =>
  import("../components/contextmenu/sharefilerightclick");
const dialogcheckmultidownload = () =>
  import("../components/optional/dialog-checkmultidownload");
const checkpassword = () =>
  import("../components/optional/dialog-checkpassword");
const rightcheckpassword = () =>
  import("../components/optional/dialog-rightclickcheckpassword.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    checkpassword,
    rightcheckpassword,
    sharefilerightclick,
    uploadfiles,
    InfiniteLoading,
    onebox_toolbar,
    mainrightclick,
    VueFullScreenFileDrop,
    uploadfolder,
    currentfilerightclick,
    previewfile,
    dialogcheckmultidownload,
  },
  data: function() {
    return {
      wordSearch: "",
      data_search:{},
      count_data_adv_:0,
      heardertextsearch:false,
      word_searched: false,
      param_preview:"",
      check_btn: "",
      opendialog_checklog: false,
      newdata: {},
      opendialog_right: false,
      show_multipledownload: false,
      show_download: false,
      show_clear: false,
      opendialogcheckmultidownload: false,
      confirmdelete: false,
      arrayfile: [],
      timeout: 60000,
      percentCompleted: 0,
      datetime: "",
      snackbardowload: false,
      countfile: "",
      countfolder: "",
      myDateDownload: new Date().toISOString().slice(0, 10),
      statusmutipledownload: false,
      filemultipledownload: [],
      search: "",
      page: 1,
      size: 50,
      listsize: [20, 50, 100],
      listsizemenu: [
        { value:20 },
        { value:50 },
        { value:100 }
      ],
      lists: [],
      pagination: {
        rowsPerPage: 100000,
        sortBy: "file_id",
        totalItems: 0,
        rowsPerPageItems: [1, 2, 4, 8],
      },
      opennewpreviewfile: false,
      loaddataprogress: true,
      parentfolder: "",
      loadingfile: false,
      openUploadfolder: false,
      openUploadfiles: false,
      showcurrentMenu: false,
      showMenu: false,
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
      x: 0,
      y: 0,
      singleSelect: false,
      selected: [],

      headers: [
        {
          text: "#",
          align: "center",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "35%",
          sortable: false,
        },
        {
          text: "tablefile.shareby",
          value: "file_shareby",
          width: "10%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.sharedate",
          value: "file_date",
          width: "12%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "10%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.permission",
          value: "permission",
          width: "10%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "10%",
          align: "center",
          sortable: false,
        },
      ],
      sortby: "",
      clickSort: false,
      multiSort: false,
      rootsharefile: [],
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
      checkfilemultipledownload: [],
      arrayfileidselect: [],
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.rootsharefile.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootsharefile.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 24px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    disableByPackage() {
      // return this.dataCheckPackage.type === 'Business'? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
      return false;
    },
    //สีอักษรของ headerpage ของ mobile
    headerPageMobile() {
      return "color:" + this.color.theme + ";" + "font-size: 16px; font-weight: 700; line-height: 24px;";
    },
    // ขนาดของ chip ที่แสดง headerpage
    headerChipPage() {
      return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;"
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark){
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      }
    },
    // ตัวหนา
    listOnBold() {
      return "font-size: 14px; font-weight: 700; line-height: 24px;";
    },
    // ตัวบาง
    listNoBold() {
      return "font-size: 14px; font-weight: 400; color: #636363; line-height: 24px;";
    },
    // ขอบของ sortby
    roundListSort() {
      return "border-radius: 10px; border: 1px solid #f3f5f7; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);"
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;"
      } else {
        return "background: #FFFFFF;"
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;"
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;"
      }
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
  },
  methods: {
    fn_hilightWord (text) {
      var regex = new RegExp(this.wordSearch, "gi")
      if(this.$vuetify.theme.dark){
        var response = text.replaceAll(regex, function(str) {
          return "<span style='background-color: #827917'>" + str + "</span>"
        })
      } else {
        var response = text.replaceAll(regex, function(str) {
          return "<mark>" + str + "</mark>"
        })
      }
      return response
      //return text.replaceAll(new RegExp(this.data_search.name,"gi"), `<mark>${this.data_search.name}</mark>`);
    },
    checkSort() {
      // this.loadfolder();
      this.rootsharefile = this.buff;
      this.clickSort = false;
      this.multiSort = false;
    },
    // sortFile(parameter) {
    //   this.folderSort = [];
    //   this.fileSort = [];
    //   this.sortdata = [];
    //   this.rootsharefile = [];
    //   let i, j;
    //   for (i = 0; i < parameter.length; i++) {
    //     if (parameter[i].file_type === "folder") {
    //       this.folderSort.push(parameter[i]);
    //     } else this.fileSort.push(parameter[i]);
    //   }

    //   if (this.sortby === "name") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_name.localeCompare(b.file_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_name.localeCompare(b.file_name);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_name.localeCompare(a.file_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_name.localeCompare(a.file_name);
    //       });
    //     }
    //   } else if (this.sortby === "date") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_date.localeCompare(b.file_date);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_date.localeCompare(b.file_date);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_date.localeCompare(a.file_date);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_date.localeCompare(a.file_date);
    //       });
    //     }
    //   } else if (this.sortby === "size") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //     }
    //   } else if (this.sortby === "shareby") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //     }
    //   }
    //   this.sortdata.push(this.folderSort);
    //   this.sortdata.push(this.fileSort);
    //   for (i = 0; i < this.sortdata.length; i++) {
    //     for (j = 0; j < this.sortdata[i].length; j++) {
    //       this.rootsharefile.push(this.sortdata[i][j]);
    //     }
    //   }
    //   return this.rootsharefile;
    // },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    //เอาโฟลเดอร์ออก ตอนนี้ multidownload ใช้กับโฟลเดอร์ไม่ได้
    fn_checkfilemultidownload() {
      console.log("this.filemultipledownload[i]", this.filemultipledownload);
      for (let i = 0; i < this.filemultipledownload.length; i++) {
        console.log(this.filemultipledownload[i].file_type);
        if (this.filemultipledownload[i].file_type !== "folder") {
          this.checkfilemultipledownload.push(this.filemultipledownload[i]);
          console.log(
            "this.filemultipledownload[i]",
            this.filemultipledownload[i]
          );
          console.log(
            "checkfilemultipledownload",
            this.checkfilemultipledownload
          );
        } else {
          continue;
        }
      }
    },
    //ใช้
    async multipledownload() {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        console.log("ไฟล์ที่เลือก", this.filemultipledownload);
        await this.fn_checkfilemultidownload();
        this.filemultipledownload = this.checkfilemultipledownload;
        this.opendialogcheckmultidownload = true;
        this.checkfilemultipledownload = [];
      }
      // this.percentCompleted = 0;
      // var d = new Date();
      // this.datetime =
      //   d.toISOString().slice(0, 10) +
      //   " " +
      //   "at" +
      //   " " +
      //   this.zeroPadding(d.getHours(), 2) +
      //   "-" +
      //   this.zeroPadding(d.getMinutes(), 2) +
      //   "-" +
      //   this.zeroPadding(d.getSeconds(), 2);
      // if (this.filemultipledownload.length < 1) {
      //   Toast.fire({
      //     icon: "warning",
      //     title: this.$t("myinboxPage.msg.nofile"),
      //   });
      // } else {
      //   let filedata = [];
      //   for (let i = 0; i < this.filemultipledownload.length; i++) {
      //     filedata.push(this.filemultipledownload[i]["file_id"]);
      //   }
      //   // console.log(filedata);
      //   let payload = {
      //     list_file: filedata,
      //     account_id: this.dataAccountId,
      //   };
      //   this.snackbardowload = true;
      //   //  this.opendialogcheckmultidownload = true;
      //   let auth = await gbfGenerate.generateToken();
      //   await this.axios
      //     .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/multiple_downloads_file", payload, {
      //       headers: { Authorization: auth.code },
      //       onDownloadProgress: (progressEvent) => {
      //         let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
      //         console.log(progresspercent);
      //         if (progresspercent >= 95) {
      //           this.percentCompleted = 95;
      //         } else {
      //           this.percentCompleted = progresspercent;
      //         }
      //       },
      //       withCredentials: false,
      //       responseType: "arraybuffer",
      //     })
      //     .then((response) => {
      //       this.statusmutipledownload = false;
      //       // console.log(response);
      //       if (response.statusText === "OK") {
      //         this.percentCompleted = 100;
      //       }
      //       //     const url = window.URL.createObjectURL(new Blob([response.data]));
      //       //     const link = document.createElement("a");
      //       //     link.href = url;
      //       //     link.setAttribute("download",datetime+".zip");
      //       //     document.body.appendChild(link);
      //       //     link.click();
      //       const blob = new Blob([response.data]);
      //       const content = response.headers["content-type"];
      //       saveAs(blob, this.datetime + ".zip");
      //       this.clearmultipledownload();
      //     })
      //     .catch((error) => {
      //       Toast.fire({
      //         icon: "error",
      //         title: this.$t("myinboxPage.msg.apierror"),
      //       });
      //       console.log(error);
      //     });
      // }
    },
    // multipledownload() {
    //   var d = new Date();
    //   let datetime =d.toISOString().slice(0,10)+" "+"at"+" "+this.zeroPadding(d.getHours(),2)+"-"+this.zeroPadding(d.getMinutes(),2)+"-"+this.zeroPadding(d.getSeconds(),2);
    //   if (this.filemultipledownload.length < 1) {
    //     Toast.fire({
    //       type: "warning",
    //       title: this.$t("myinboxPage.msg.nofile")
    //     });
    //   } else {
    //     this.$store.dispatch("show_loading", true);
    //     let filedata = [];
    //     for (let i = 0; i < this.filemultipledownload.length; i++) {
    //       filedata.push(this.filemultipledownload[i]["file_id"]);
    //     }

    //     let payload = {
    //       list_file: filedata,
    //       account_id: this.dataAccountId
    //     };

    //     this.axios
    //       .post(
    //         process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
    //           "/api/multiple_downloads_file",
    //         payload,
    //         {
    //           withCredentials: false,
    //           responseType: "blob"
    //         }
    //       )
    //       .then(response => {
    //         this.statusmutipledownload = false;
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute("download",datetime+".zip");
    //         document.body.appendChild(link);
    //         link.click();
    //         this.$store.dispatch("show_loading", false);
    //         this.clearmultipledownload();
    //       })
    //       .catch(error => {
    //         this.$store.dispatch("show_loading", false);
    //         Toast.fire({
    //           icon: "error",
    //           title: this.$t("myinboxPage.msg.apierror")
    //         });
    //         console.log(error);
    //       });
    //   }
    // },
    filteredItems(search) {
      this.search = search;
      if(this.search.length > 0){
        this.word_searched = true
      } else {
        this.word_searched = false
      }
    },
    async loadsharefolder() {
      this.heardertextsearch = false;
      this.loaddataprogress = true
      this.rootsharefile = [];
      this.buff = [];
      // console.log(this.dataAccountActive["type"]);
      // if (this.dataAccountActive["type"] === "Business") {
      //   let datafolder = {};
      //   datafolder["file_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
      //   datafolder["file_name"] = this.$store.getters.dataTypeusercurrent(
      //     this.$store.state.account_active["type"],
      //     this.$t("default")
      //   );
      //   datafolder["file_lastdtm"] = "-";
      //   datafolder["file_owner_eng"] = "-";
      //   datafolder["file_owner_th"] = "-";
      //   datafolder["file_size"] = "-";
      //   datafolder["file_department"] = "";
      //   datafolder["file_status"] = "M";
      //   datafolder["file_type"] = "folder";
      //   datafolder["file_icon"] = ["mdi-folder-network", "blue darken-4"];
      //   this.rootsharefile.push(datafolder);
      //   this.buff.push(datafolder);
      // }
      var payload = {
        account_id: this.dataAccountId,
        status: "",
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FOLDER +
            "/api/taxid/get_sharefolder",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          // console.log("apipipi",response);
          if (response.data.status === "OK") {
            console.log("folder", response);
            // this.rootfile = response.data.folder
            // if (response.data.folder.length) {
            //   this.page += 1;
            //   this.rootfile.push(...response.data.folder);
            //   $state.loaded();
            // } else {
            //   $state.complete();
            // }
            var i;
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              datafolder["account_reciever"] =
                response.data.data[i]["account_id_reciever"];
              datafolder["account_sender"] =
                response.data.data[i]["account_id_sender"];
              datafolder["business_id_reciever"] =
                response.data.data[i]["business_id_reciever"];
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_size"] = "-";
              datafolder["file_status"] =
                response.data.data[i]["status_folder"];
              datafolder["file_name"] = response.data.data[i]["folder_name"];
              datafolder["file_owner_eng"] =
                response.data.data[i]["sender_name"];
              datafolder["file_owner_th"] =
                response.data.data[i]["sender_name"];
              datafolder["file_linkshare"] = "";
              datafolder["file_date"] = response.data.data[i]["share_dtm"];
              datafolder["file_lastdtm"] = "";
              datafolder["file_type"] = "folder";
              datafolder["file_status_share"] = "Y";
              datafolder["file_permission"] = response.data.data[i]["status"];
              datafolder["file_status_sharelink"] = "";
              datafolder["file_department"] = "";
              datafolder["file_icon"] = ["folder", "#ffc107"];
              datafolder["permission_account"] =
                response.data.data[i]["permission_account"];
              datafolder["file_permission_2"] =
                response.data.data[i]["permission_account"];
              datafolder["permission_department_setting"] =
                response.data.data[i]["permission_department_setting"];
              datafolder["permission_setting"] =
                response.data.data[i]["permission_setting"];
              datafolder["status_check"] = response.data.data[i]["status"];
              datafolder["relation_status"] =
                response.data.data[i]["relation_status"];
              datafolder["relation_name"] =
                response.data.data[i]["relation_name"];
              datafolder["relation_id"] = response.data.data[i]["relation_id"];
              datafolder["starred_id"] = response.data.data[i]["starred_id"];
              datafolder["status_lock_data"] =
                response.data.data[i]["status_lock_data"];
              datafolder["status_lock_data"] =
                response.data.data[i]["status_lock_data"];
              datafolder["editing_status"] = "";
              datafolder["last_update_name"] =
                response.data.data[i]["last_update_name"];
                

              // if(this.dataAccountActive["type"] === "Business"){
              //   if("datafolder",datafolder["permission_account"].view_only === "True"){
              //     console.log("2",datafolder);

              //   this.rootsharefile.push(datafolder);
              //   }
              // }else{

              //   this.rootsharefile.push(datafolder);
              // }
              this.rootsharefile.push(datafolder);
              this.buff.push(datafolder);
            }
            console.log("rootfilefolder", this.rootsharefile);

            if (this.clickSort === true) {
              this.rootsharefile.push(this.sortFile(this.rootsharefile));
            }

            this.loadsharefile();
            this.loaddataprogress = false;
            this.countfolder = response.data.data.length;
          } else {
            this.loadsharefile();
            this.countfolder = 0;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้",
          });
        });
    },
    async loadsharefile() {
      var payload = {
        account_id: this.dataAccountId,
        status: "",
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/taxid/get_sharefile",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("file", response);
            var i;
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              let typefile = response.data.data[i]["filename"].split(".");
              datafolder["account_reciever"] =
                response.data.data[i]["account_id_reciever"];
              datafolder["account_sender"] =
                response.data.data[i]["account_id_sender"];
              datafolder["business_id_reciever"] =
                response.data.data[i]["business_id_reciever"];
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_name"] = response.data.data[i]["filename"];
              datafolder["file_size"] = response.data.data[i]["size_file"];
              datafolder["file_status"] = response.data.data[i]["status_file"];
              datafolder["file_owner_eng"] =
                response.data.data[i]["sender_name"];
              datafolder["file_owner_th"] =
                response.data.data[i]["sender_name"];
              datafolder["file_linkshare"] = "";
              datafolder["file_status_share"] = "Y";
              datafolder["file_status_sharelink"] = "";
              datafolder["file_permission"] = response.data.data[i]["status"];
              datafolder["file_type"] = typefile[typefile.length - 1];
              datafolder["file_department"] = "";
              datafolder["file_date"] = response.data.data[i]["share_dtm"];
              datafolder["file_lastdtm"] = "";
              datafolder["file_icon"] = this.seticon_new(
                typefile[typefile.length - 1]
              );
              datafolder["permission_account"] =
                response.data.data[i]["permission_account"];
              datafolder["file_permission_2"] =
                response.data.data[i]["permission_account"];
              datafolder["permission_department_setting"] =
                response.data.data[i]["permission_department_setting"];
              datafolder["permission_setting"] =
                response.data.data[i]["permission_setting"];
              datafolder["status_check"] = response.data.data[i]["status"];
              datafolder["relation_status"] =
                response.data.data[i]["relation_status"];
              datafolder["relation_name"] =
                response.data.data[i]["relation_name"];
              datafolder["relation_id"] = response.data.data[i]["relation_id"];
              datafolder["starred_id"] = response.data.data[i]["starred_id"];
              datafolder["status_lock_data"] =
                response.data.data[i]["status_lock_data"];
              datafolder["editing_status"] = typefile[typefile.length - 1] === 'folder' ? "" : response.data.data[i]["editing_status"];
              datafolder["last_update_name"] =
                response.data.data[i]["last_update_name"];
              // if(this.dataAccountActive["type"] === "Business"){
              //   if("datafolder",datafolder["permission_account"].view_only === "True"){
              //     console.log("2",datafolder);

              //   this.rootsharefile.push(datafolder);
              //   }
              // }else{
              //   this.rootsharefile.push(datafolder);
              // }
              this.rootsharefile.push(datafolder);
              this.buff.push(datafolder);
              // console.log("status", datafolder["file_permission"]);
            }
            console.log("rootfilefile", this.rootsharefile);

            if (this.clickSort === true) {
              this.rootsharefile.push(this.sortFile(this.rootsharefile));
            }
            this.loaddataprogress = false;
            this.countfile = response.data.data.length;
          } else {
            this.loaddataprogress = false;
            this.countfile = 0;
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    calculatedate(parameter) {
      let date = [];
      let finalDate;
      if (parameter) {
        date.push(parameter[0] + parameter[1] + parameter[2] + parameter[3]);
        date.push(parameter[4] + parameter[5]);
        date.push(parameter[6] + parameter[7]);
        date.push(parameter[8] + parameter[9]);
        date.push(parameter[10] + parameter[11]);
        date.push(parameter[12] + parameter[13]);
        finalDate =
          date[2] +
          "/" +
          date[1] +
          "/" +
          date[0] +
          " " +
          date[3] +
          ":" +
          date[4] 
          // +
          // ":" +
          // date[5];
      } else {
        finalDate = "-";
      }

      return finalDate;
    },
    loadstorage() {
      this.$store
        .dispatch("check_storage", this.dataAccountId)
        .then((res) => {});
    },
    sendParentfolder() {
      // if (this.$route.params.id === undefined) {
      this.parentfolder = this.account_active["directory_id"];
      // } else {
      //   this.parentfolder = this.$route.params.id;
      // }
    },
    async setPermissionFileStar(parameter, status, key) {
      // เช็คไฟล์/โฟลเดอร์ล็อค
      if (parameter.status_lock_data === "Y" && key === "st") {
        console.log("setPermissionFileStar", parameter, status);
        if (parameter.file_type === "folder") {
          this.opendialog_checklog = true;
          console.log(parameter);
          this.newdata = parameter;
          this.star_status = status;
          this.check_btn = key;
        } else {
          this.check_btn = key;
          this.opendialog_right = true;
          this.newdata = parameter;
          this.star_status = status;
        }
      } else {
        let api;
        let payload;
        let msgres;
        console.log(parameter);
        // if (parameter.account_sender !== this.dataAccountId) {
        // if (parameter.file_type === "folder") {
        //   payload = {
        //     id: parameter.file_id,
        //     account_id: this.dataAccountId,
        //     status_folder: status,
        //   };

        //   api = "/api/update_share_statusfolder";
        // } else {
        //   payload = {
        //     id: parameter.file_id,
        //     account_id: this.dataAccountId,
        //     status_file: status,
        //   };
        //   api = "/api/update_share_statusfile";
        // }
        if (status === "S") {
          payload = {
            starred_id: [parameter.starred_id],
            data_id: [parameter.file_id],
            account_id: this.dataAccountId,
            process: "insert",
          };
          api = "/api/multiple_star";
        } else {
          payload = {
            starred_id: [parameter.starred_id],
            data_id: [parameter.file_id],
            account_id: this.dataAccountId,
            process: "delete",
          };
          api = "/api/multiple_star";
        }
        console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              if (status === "S") {
                // msgres = "ติดดาว " + parameter.file_name + " สำเร็จ";
                msgres =
                  this.$t("toast.text.starred") +
                  parameter.file_name +
                  this.$t("toast.text.success");
              } else if (status === "T") {
                // msgres = "นำ " + parameter.file_name + " ออกสำเร็จ";
                msgres =
                  this.$t("toast.text.remove") +
                  parameter.file_name +
                  this.$t("toast.text.success");
              } else {
                // msgres = "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ";
                msgres =
                  this.$t("toast.text.canclestart") +
                  parameter.file_name +
                  this.$t("toast.text.success");
              }

              Toast.fire({
                icon: "success",
                title: msgres,
              });
              // this.$emit('closedialog')
              this.confirmdelete = false;
              this.loadsharefolder();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              // title: "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
              title:
                this.$t("toast.text.cannotstar") +
                parameter.file_name +
                this.$t("toast.text.textth"),
            });
          });
        // } else {
        // Toast.fire({
        //   icon: "error",
        //   // title: "ไม่สามารถติดดาวไฟล์นี้ได้"
        //   title:this.$t("toast.text.cannotstar") + parameter.file_name + this.$t("toast.text.textth")
        // });
        // }
      }
    },

    rightclickfileandfolder(e, file) {
      // เมื่อคลิกจุดสามจุด ให้โฟกัสรายการที่เลือก
      if (!this.arrayfileidselect.includes(file.file_id)) {
        this.arrayfileidselect = [file.file_id]
      }
      console.log(e);
      e.preventDefault();
      this.sendParentfolder();
      this.showcurrentMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.$nextTick(() => {
        console.log(file);
        this.currentfile = file;
        this.showcurrentMenu = true;
      });
    },

    gotodirectory(folderid, type, _item) {
      console.log("_item", _item);
      let permission = JSON.stringify(_item.permission_account);

      if (type === "folder") {
        sessionStorage.setItem("permission", permission);
        this.$router.replace({ path: "/directorysharefile/" + folderid });
      }
    },

    show(e) {
      console.log(e);
      e.preventDefault();
      this.sendParentfolder();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        if (this.showcurrentMenu === true) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }
      });
    },

    fn_newpreviewfile(file) {
      console.log(file);
      if (file.file_type !== "folder") {
        // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
          // console.log("checkinet_jv",checkinet_jv);
          // if(checkinet_jv === -1){
        // let checkinet_jv = VueCookies.get("feature_onlyoffice")
        // if(checkinet_jv === 'false'){
        let checkinet_jv = this.feature_onlyoffice;
          if(checkinet_jv === false){
            this.param_preview = "preview";
            this.opennewpreviewfile = true;
          }else{
            if(
              // (['04', '05', '06'].includes(file.file_permission_2)) &&
              (file.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.file_type.toLowerCase()) || 
              file.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.data_type.toLowerCase()))
            ){
              this.param_preview = "editfileonline";
            }else{
              this.param_preview = "preview";
            }
          }
        this.currentfile = file;
        this.arrayfile = this.rootsharefile;
        // this.opennewpreviewfile = true;
        if(file.editing_status === 'S'){
            Toast.fire({
                icon: "warning",
                title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
              });
          }else if(file.editing_status === 'Y'){
            if(file.last_update_name.name_th === 'ฉัน'){
              this.opennewpreviewfile = true;
            }else{
              Toast.fire({
                icon: "warning",
                title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
              });
            }
          }else{
            this.opennewpreviewfile = true;
          }
      }
    },

    onDrop(files) {
      console.log(files);
    },

    seticon(parameter) {
      // console.log(parameter);
      // let parameter = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls" || parameter === "xlsb") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word-outline", "primary"];
      } else if (parameter === "html") {
        dataicon = ["mdi-language-html5", "#FF603F"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link-outline", "pink"];
      } else {
        dataicon = ["mdi-file-outline", "black"];
      }

      return dataicon;
    },

    seticon_new(parameter) {
      // console.log(parameter);
      // let parameter = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls" || parameter === "xlsb") {
        // dataicon = ["mdi-file-excel", "green"];
        dataicon = ["mdi-file-excel", "#00733b"];
      } else if (parameter === "docx" || parameter === "doc") {
        // dataicon = ["mdi-file-word", "primary"];
        dataicon = ["mdi-file-word", "#0263d1"];
      }else if (parameter === "html") {
        // dataicon = ["mdi-language-html5", "orange"];
        dataicon = ["mdi-language-html5", "#FF603F"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        // dataicon = ["mdi-file-powerpoint", "orange"];
        dataicon = ["mdi-file-powerpoint", "#e03303"];
      } else if (parameter === "pdf") {
        // dataicon = ["mdi-file-pdf", "red"];
        dataicon = ["mdi-file-pdf", "#e5252a"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        // dataicon = ["mdi-file-image", "blue-grey"];
        dataicon = ["mdi-file-image", "#0ac963"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },

    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },
    //รับข้อมูลจาก toolbar
    fn_advancesearch(_param, wordSearch) {
       this.heardertextsearch = true;
      this.count_data_adv_ = _param.data.total;
      // this.disablescrolling = true;
      this.rootsharefile = [];
      this.buff = [];
      this.wordSearch = wordSearch
      const datafolder = gb_advanceSearch.setDataCompleteAdvanceSearch_(
        _param,
        this.dataAccountId
      );
      console.log("datafolder", datafolder);
      let count_file = datafolder.filter((item) => {
        return item.file_type === "file";
      });
      let count_folder = datafolder.filter((item) => {
        return item.file_type === "folder";
      });
      this.totaldata = {
        totalall: count_file.length + count_folder.length,
        totalfile: count_file.length,
        totalfolder: count_folder.length,
      };
      this.rootsharefile = datafolder;
      console.log("this.rootfile1",this.rootsharefile);
      for (let index = 0; index < this.rootsharefile.length; index++) {
        const element = this.rootsharefile[index];
        element.file_thumbnail = "";
        // let typeFile = element.file_name.split(".");
        // let typefile = response.data.data[i].data_type === "folder" ? "folder" : element.file_name.split(".");
        // this.fn_loadthumbnail(
        //   element.file_id,
        //   typeFile[typeFile.length - 1],
        //   element.file_size,
        //   element.status_lock_data
        // );
      }
      this.buff = datafolder;
      this.filemultipledownload = [];
    },
    selectItemTable(item) {
      return this.selectColor(item, "table")
        ? "background-color:#D6EAF8;"
        : "opacity: unset;";
    },
    selectColor(e, type) {
      if (type === "table") {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      } else {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      }
    },
    setSelectitemtable(file) {
      console.log('file',file)
      if (file.file_id) {
        if (this.statusmutipledownload === false) {
          this.arrayfileidselect = [];
          if (file.status_lock_data === "N") {
            this.arrayfileidselect = [file.file_id]
          }
        }
      }
    },
  },
  mounted() {
    var self = this;
    self.loadsharefolder();
  },
  created() {},
};
</script>
<style>
/* #demo {
  width: 100%;
  height: 100%;
} */

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
}

#dropzone {
  min-height: 100%;
}

#thiscontainer_sharedwithme {
  height: calc(101vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}

.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>
